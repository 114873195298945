input.parsley-success,
select.parsley-success,
textarea.parsley-success {
    color: #468847;
    background-color: #DFF0D8;
    border: 1px solid #D6E9C6;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
    color: #B94A48;
    background-color: #F2DEDE;
    border: 1px solid #EED3D7;
}

.parsley-errors-list {
    margin: 2px 0 3px;
    padding: 0;
    list-style-type: none;
    font-size: 0.9em;
    line-height: 0.9em;
    opacity: 0;

    transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
}

.parsley-errors-list.filled {
    opacity: 1;
}
.masquerade{width:100%; background: red; z-index: 2000; color: #fff;font-size: 12px; padding: 5px;}
    .masquerade a{ color: #fff; text-decoration: underline;}

.usertabs{ margin-bottom:10px;}
div#usersTable_wrapper {overflow-x: scroll;}
.injected-body.remove-left-margins .main-footer{ margin-left:0; position: relative;}
.injected-body.remove-left-margins .main-header .navbar{ margin-left:0 !important;}
.login-box-body .form-control-feedback, .register-box-body .form-control-feedback {
    top: 0;
}
#amazonPayRefundModal .modal-dialog {width: 250px; text-align: center;}
#amazonPayRefundModal .modal-footer{text-align: center;}

/*Attribute Sets Page*/
td.searchUsagesDepends{ display: none;}

/*Order Layout*/
/*My Orders*/
.orders > .order{ margin:15px 0; border:1px solid #ddd; border-radius: 5px;float: left; width: 100%;}
    .orders > .order > .orderhead{background:#f6f6f6; padding:12px 20px; border-radius: 5px 5px 0 0;}
        .orderplaced,.ordertotal {display: inline-block; vertical-align: top;}
    .orders > .order > .orderbody{ background: #fff; padding:20px; border-radius: 0 0 5px 5px;float: left; width: 100%;}
        .order-row {margin-bottom: 10px; float: left; width: 100%;}
            .orders > .order > .orderbody .orderleft{ width:75%; float: left;}
                .orderitemname{margin-bottom:5px;}
                .orderitemprice{ margin-bottom:5px; color: #d0481f;}
                .orders > .order > .orderbody .orderleft .orderimg{float: left; width: 100px; height: 100px;}
                .afterimg button[type="submit"]{padding: 5px 10px;}
            .orders > .order > .orderbody .orderright{ width:25%; float: left; vertical-align: top;}
    .orders > .order > .orderbody .orderright a.button{margin-bottom: 10px;}
        .orderright a.button{
            background: #eff0f3;
            background: -webkit-linear-gradient(top,#f7f8fa,#e7e9ec);
            background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
            display: block;
            position: relative;
            overflow: hidden;
            height: 29px;
            line-height: 29px;
            box-shadow: 0 1px 0 rgba(255,255,255,.6) inset;
            border-radius: 2px;
            color: #111;
            text-align: center;
            border:1px solid #6e6e6e;
        }
        .orderright a.button.yellow{
            background: #f3d078;
            background: -webkit-linear-gradient(top,#f7dfa5,#f0c14b);
            background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
        }

.address-wrapper-table{
    display: table;
    width:100%;
    margin-bottom: 20px;
}
.address-wrapper-cell {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    vertical-align: middle;
    display: table-cell;
    height: 175px;
}
.address-wrapper-cell .edit-row {
    padding-top: 20px;
}
.newaddress .address-wrapper-cell{
    text-align: center;
    border: 2px dashed #ccc;
}
.newaddress a{
    color: #848484;
    font-size:28px;
}
.row.analyticsfilter{ margin:20px 0;}
    .analyticsfilter button.active{ background: #ff463f; border-radius: 5px;}
        .analyticsfilter button.active a{color: #fff;}
.clickcell{background: #fff; border-radius: 5px; position: relative; padding:10px; text-align: center; cursor: pointer}
    .clickcell > span{
        position: absolute;
        top: -13px;
        right: -10px;
        background: #dadada;
        padding: 5px 7px;
        border-radius: 4px;
        color: #222;
        font-weight: bold;
        cursor: pointer;
        z-index: 2;
    }
    .clickcell img{max-width: 100%; height: auto;}
    .clickcell:hover > span{
        background: red;
        color: #fff;
    }
    .searches{
        display: none;
        position: absolute;
        top:0;
        padding:10px;
        background: rgba(0,0,0,0.8);
        color: #fff;
        height:100%;
        width:100%;
        z-index: 1;
        left:0;
        text-align: left;
        border-radius: 5px;
    }
.searches strong{ font-size:16px; margin-bottom: 10px; text-align: center; display: block;}
    .clickcell:hover .searches{ display: block;}